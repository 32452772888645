// 用来检测屏幕的宽度，从而确定元素的显示和隐藏
export default {
    created () {
        window.addEventListener('resize', (e) => {
            this.innerWidth = e.target.innerWidth;
            this.innerHeight = e.target.innerHeight;
        });
    },
    computed: {
        isPc () {
            return this.innerWidth >= 1024;
        },
    },
    data () {
        return {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
        }
    }
}