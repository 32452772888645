export default {
  name: 'FooterForPC',

  mounted() {
    this.viewHeight = this.$refs.v1.offsetHeight;
  },

  data() {
    return {
      viewHeight: 0
    };
  }

};