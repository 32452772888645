import HeaderForPC from 'c/HeaderForPC.vue';
import FooterForPC from 'c/FooterForPC.vue';
import FooterForMobile from 'c/FooterForMobile.vue';
import pcOrMobile from 'mixins/pcOrMobile.js';
import 'element-plus/es/components/dialog/style/css';
import { ElDialog } from 'element-plus';
export default {
  name: 'App',
  mixins: [pcOrMobile],
  components: {
    HeaderForPC,
    FooterForPC,
    FooterForMobile,
    ElDialog
  },

  //   mounted() {
  //     var mql = window.matchMedia('(min-width: 1000px)');
  //     mqCallback(mql);
  //     mql.addListener(mqCallback);
  //     function mqCallback(mql) {
  //       if (mql.matches) {
  //         document.body.background = 'pink';
  //       } else {
  //         document.body.background = 'lightblue';
  //       }
  //     }
  //   },
  data() {
    return {
      show: false,
      dialogVisible: false,
      //弹出框显示与隐藏
      dialogVisibleMobile: false,
      //弹出框显示与隐藏(移动端)
      code: 1
    };
  },

  methods: {
    jump(navigate) {
      this.show = false;
      navigate();
    },

    eventFromChildRoute(params) {
      if (params.type == 'showQRcode') {
        this.code = params.params.code;

        if (this.isPc) {
          this.dialogVisible = params.params.show;
        } else {
          this.dialogVisibleMobile = params.params.show;
        }
      }
    }

  }
};